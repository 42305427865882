import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Gallery from 'components/gallery';
import Modal from 'containers/modal';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import MEDIA from '../helpers/mediaTemplates';

const Centerer = styled.div`
  padding-top: 15px;
  div {
    margin: 0 auto;
    margin-top: 8rem;
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
  }
  [data-reach-dialog-content] {
    width: 30vh !important;
  }
`;

const ModalHeader = styled.div`
  font-size: 5rem;
  text-align: center;
  strong {
    font-weight: bolder;
  }
  ${MEDIA.DESKTOP`
    font-size: 4rem;
  `}
`;
const ModalParagraph = styled.p`
  display: block;
  margin-top: 4rem;
  font-size: 3rem;
  ${MEDIA.DESKTOP`
    font-size: 2rem;
  `}
`;
const ModalContainer = styled.div``;

const Index = ({ data }) => {
  const imageData = data.homeJson.santacruz.image.childImageSharp.fluid;
  return (
    <Layout>
      <BackgroundImage
        Tag="section"
        className="some-class"
        fluid={imageData}
        backgroundColor={'#040e18'}
      >
        <Centerer>
          <Box>
            <Title as="h2" size="large" isHtml={true}>
              {data.homeJson.content.childMarkdownRemark.html}
            </Title>
            <Modal>
              <ModalContainer>
                <ModalHeader>
                  Thank You for considering <strong>calmForecast.</strong>
                </ModalHeader>
                <ModalParagraph>
                  We look forward to learning more about your project and how we
                  can best help.
                  <br />
                  <br />
                  Email us a brief introduction to your project and we can
                  schedule a meeting to review it closer.
                  <br />
                  <br />
                  <a href="mailto:kumar@calmforecast.com">
                    kumar@calmforecast.com
                  </a>
                </ModalParagraph>
              </ModalContainer>
            </Modal>
          </Box>
        </Centerer>
        <Gallery items={data.homeJson.gallery} />
      </BackgroundImage>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      santacruz {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
