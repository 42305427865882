import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './title.css';

const Title = ({ children, as = 'span', size, isHtml = false }) => {
  if (isHtml) {
    return (
      <Text
        as={as}
        size={size}
        dangerouslySetInnerHTML={{
          __html: children,
        }}
      />
    );
  }
  return (
    <Text as={as} size={size}>
      {children}
    </Text>
  );
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  size: PropTypes.oneOf(['large']),
  isHtml: PropTypes.bool,
};

export default Title;
